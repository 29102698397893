import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  Hidden,
  LinearProgress,
} from '@material-ui/core';
import EmailIcon from '@mui/icons-material/Email';

import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import HelpsAccordion from './HelpsAccordion';
import HelpsForm from './HelpsForm';
import OrganisationSignUp from '../../Pages/Authentication/Organisation/SignUp';
import OrganisationActions from '../../Redux/OrganisationRedux';
import Roles from '../../Acl/Roles';
import districtsHelps from './HelpsData/districtsHelps';
import pensionFundsHelps from './HelpsData/pensionFundsHelps';
import healthInsuranceHelps from './HelpsData/healthInsuranceHelps';
import foresightHelpsHelps from './HelpsData/foresightHelps';
import specialPlanHelps from './HelpsData/specialPlanHelps';
import insuranceHelps from './HelpsData/insuranceHelps';
import ContactSuccess from '../../Components/Organisations/Shared/ContactSuccess';
import ButtonCallMeBackInModal from '../../Components/Shared/ButtonCallMeBackInModal';
import { Metrics } from '../../Themes';
import EmailActions from '../../Redux/EmailRedux';
import Footer from '../../Components/Layout/Footer';
import Seo from '../../Routing/Seo';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  headerContainer: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 2px 3px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12)',
  },
  headerLayout: {
    ...LayoutStyles.fullWidthLayout,
    width: '100%',
    padding: '20px',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  subtitle: {
    paddingLeft: 2,
  },
  contactCardContainer: { margin: 16 },
  contactCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  formWidth: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formTransition: {
    transitionProperty: 'width',
    transitionDuration: '300ms',
  },
  btnContainer: { textAlign: 'center' },
  btn: {
    margin: theme.spacing(2),
    backgroundColor: theme.chb.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
  resultContainer: { position: 'relative' },
  accordionContainer: {
    position: 'relative',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  gradient: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: `linear-gradient(to bottom, #ffffff00, ${theme.palette.background.default} 40%)`,
  },
  signInCard: {
    margin: 16,
    position: 'absolute',
    top: '55%',
  },
  buttonLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    textTransform: 'none',
    padding: 0,
    marginLeft: theme.spacing(1),
    marginBottom: 3,
  },
  articleBtn: {
    backgroundColor: 'white',
    color: theme.chb.primary.main,
  },
}));

const HelpedTool = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    helper: queryParam.get('helper'),
    helped: queryParam.get('helped'),
  };
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const organisation = useSelector(state => state.organisation.organisation);
  const [helperSituation, setHelperSituation] = React.useState({
    pathology: '',
    hasHelped: '',
    district: null,
    pensionFund: '',
    healthInsurance: '',
    foresight: '',
    specialPlan: '',
    insurance: '',
  });
  const [helpedSituation, setHelpedSituation] = React.useState({
    pathology: '',
    district: null,
    pensionFund: '',
    healthInsurance: '',
    foresight: '',
    specialPlan: '',
    insurance: '',
  });
  const [helperHelps, setHelperHelps] = React.useState(null);
  const [helpedHelps, setHelpedHelps] = React.useState(null);
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [openSignUpPending, setOpenSignUpPending] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const sectionRefs = React.useRef([]);
  sectionRefs.current.results = React.createRef();
  const showHelpedHelps = helpedHelps && helperSituation?.hasHelped;
  const helperResults = urlParams.helper?.split('|');
  const helpedResults = urlParams.helped?.split('|');

  React.useEffect(() => {
    if (helperResults) {
      const helperSitu = Object.assign(helperSituation);
      const helpedSitu = Object.assign(helpedSituation);
      helperResults?.forEach(result => {
        if (!result.includes('hasHelped')) {
          helperSitu['hasHelped'] = false;
        }
      });
      helperResults?.forEach(result => {
        const value = result.split('-');
        const key = value[0];
        helperSitu[key] = value[1];
      });
      helpedResults?.forEach(result => {
        const value = result.split('-');
        const key = value[0];
        helpedSitu[key] = value[1];
      });
      setHelperSituation(helperSitu);
      setHelpedSituation(helpedSitu);
      handleClickSubmit();
    }
    setIsLoaded(true);
  }, []);

  const isInvalidForm =
    helperSituation.pathology === '' ||
    helperSituation.hasHelped === '' ||
    helperSituation.district === null ||
    (helperSituation?.hasHelped &&
      (helpedSituation.pathology === '' ||
        helpedSituation.hasHelped === '' ||
        helpedSituation.district === null));

  React.useEffect(() => {
    dispatch(OrganisationActions.getOrganisationRequest('aidants', 'centre-helene-borel'));
  }, []);

  React.useEffect(() => {
    if (helperHelps) {
      window.scrollTo({
        top: sectionRefs.current.results.current.offsetTop - Metrics.navBarHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [helperHelps]);

  const handleClickSubmit = event => {
    event?.preventDefault();
    setHelperHelps({
      Département: getHelperSituation(districtsHelps[helperSituation.district]),
      'Caisse de retraite': getHelperSituation(pensionFundsHelps[helperSituation.pensionFund]),
      'Complémentaire santé': getHelperSituation(
        healthInsuranceHelps[helperSituation.healthInsurance],
      ),
      'Organisme de prévoyance': getHelperSituation(foresightHelpsHelps[helperSituation.foresight]),
      'Régime spéciaux': getHelperSituation(specialPlanHelps[helperSituation.specialPlan]),
      Assurance: getHelperSituation(insuranceHelps[helperSituation.insurance]),
    });
    if (helpedSituation.pathology) {
      setHelpedHelps({
        Département: getHelpedSituation(districtsHelps[helpedSituation.district]),
        'Caisse de retraite': getHelpedSituation(pensionFundsHelps[helpedSituation.pensionFund]),
        'Complémentaire santé': getHelpedSituation(
          healthInsuranceHelps[helpedSituation.healthInsurance],
        ),
        'Organisme de prévoyance': getHelpedSituation(
          foresightHelpsHelps[helpedSituation.foresight],
        ),
        'Régime spéciaux': getHelpedSituation(specialPlanHelps[helpedSituation.specialPlan]),
        Assurance: getHelpedSituation(insuranceHelps[helpedSituation.insurance]),
      });
    }
    if (helperSituation) {
      const helperResponses = Object.keys(helperSituation)
        ?.filter(hs => helperSituation[hs])
        ?.map(hs => `${hs}-${helperSituation[hs]}`)
        ?.join('|');
      let path = `?helper=${helperResponses}`;
      if (helpedSituation.pathology) {
        const helpedResponses = Object.keys(helpedSituation)
          ?.filter(hs => helpedSituation[hs])
          ?.map(hs => `${hs}-${helpedSituation[hs]}`)
          ?.join('|');
        path += `&helped=${helpedResponses}`;
      }
      history.push(path);
    }
  };

  const getHelperSituation = category => {
    if (category) {
      let help = [];
      if (helperSituation.hasHelped && helpedSituation.pathology) {
        const helperCategory = category?.helper;
        const result = helperCategory && helperCategory[helpedSituation.pathology];
        result
          ? help.push(result)
          : help.push({ condition: 'Aucune donnée correspondant à cette recherche' });
      }
      if (helperSituation.pathology !== '' && helperSituation.pathology !== 'other') {
        const helpedCategory = category?.helped;
        const result = helpedCategory && helpedCategory[helperSituation.pathology];
        result
          ? help.push(result)
          : help.push({ condition: 'Aucune donnée correspondant à cette recherche' });
      }
      return help;
    }
    return [{ condition: 'Veuillez renseigner le champ correspondant pour obtenir un résultat.' }];
  };

  const getHelpedSituation = category => {
    if (category) {
      let help = [];
      if (helpedSituation.pathology) {
        const helpedCategory = category?.helped;
        const result = helpedCategory && helpedCategory[helpedSituation.pathology];
        result
          ? help.push(result)
          : help.push({ condition: 'Aucune donnée correspondant à cette recherche' });
      }
      return help;
    }
    return [
      { condition: "Veuillez renseigner le champ correspondant pour bénéficier d'un résultat." },
    ];
  };

  const handleSubmitClick = userEmail => {
    setEmail(userEmail);
    setOpenSignUp(false);
    setOpenSignUpPending(true);
  };

  const handleSendEmail = () => {
    const emailInfo = {
      subject: 'Centre Hélène Borel : Vos résultats de recherche d’aide',
      intro:
        'Retrouvez ici les résultats de votre recherche, obtenus grâce à l’outil de recherche d’aide en ligne du Centre Hélène Borel.',
      content: null,
      button_label: 'Accéder à mes résultats',
      button_url: `https://www.aidant-ma-vie.fr/aide-au-financement${location.search}`,
    };
    dispatch(EmailActions.sendEmailRequest(emailInfo));
  };

  return (
    <Grid>
      {/* HEADER SECTION */}
      <Helmet>
        {Seo.title(t('CHB.SEO.HELP_TOOL.TITLE'))}
        {Seo.description(t('CHB.SEO.HELP_TOOL.DESCRIPTION'))}
        {Seo.image(organisation && organisation?.holding?.cover_url)}
        {Seo.canonical(organisation && organisation?.holding?.league_id, match.url)}
      </Helmet>
      <Grid className={classes.headerContainer}>
        <Grid className={classes.headerLayout}>
          <Typography variant="h2" component="h1" className={classes.bold}>
            {t('CHB.HELP_TOOL.TITLE')}
          </Typography>
          <Typography variant="body2" paragraph className={classes.subtitle}>
            {t('CHB.HELP_TOOL.DESCRIPTION')}
          </Typography>
          <Typography
            variant="subtitle2"
            paragraph
            className={classes.subtitle}
            style={{ marginTop: -16 }}
          >
            {t('CHB.HELP_TOOL.UPDATE')}
          </Typography>
        </Grid>
        {!isLoaded && (
          <LinearProgress
            classes={{
              colorPrimary: classes.colorPrimary,
              barColorPrimary: classes.barColorPrimary,
            }}
          />
        )}
      </Grid>
      {/* CONTENT SECTION */}
      {isLoaded && (
        <Grid className={classes.layout}>
          {/* CONTACT CARD */}
          <Grid item className={classes.contactCardContainer}>
            <Card className={classes.contactCard}>
              <CardContent>
                <Typography variant="h4" component="h2" gutterBottom>
                  {t('CHB.HELP_TOOL.CONTACT.TITLE')}
                </Typography>
                <Typography variant="body2" component="h3" gutterBottom>
                  {t('CHB.HELP_TOOL.CONTACT.SUBTITLE')}
                </Typography>
              </CardContent>
              <CardContent style={{ flexShrink: 0 }}>
                <ButtonCallMeBackInModal
                  withContainedButton
                  organisation={organisation}
                  withPostCode
                  withEmail
                  withPhone
                  buttonLabel={t('CONTACT_US')}
                  descriptionPlaceHolder={t('CHB.ASK_CONTACT.TEXT_FIELD.DESCRIPTION.PLACEHOLDER')}
                  gtmOrigin="chb-help-tool"
                  defaultDescription={t('CHB.ASK_CONTACT.TEXT_FIELD.DESCRIPTION.PLACEHOLDER')}
                  requiredDescription
                  requiredPhone
                />
              </CardContent>
            </Card>
          </Grid>
          {/* FORM */}
          <form onSubmit={handleClickSubmit}>
            <Grid container justifyContent="center">
              <Grid className={classes.formWidth}>
                <HelpsForm
                  setHelperSituation={setHelperSituation}
                  helperSituation={helperSituation}
                  isRequired
                />
              </Grid>
              {/* hidden component for responsive transition width */}
              <Hidden smDown>
                <Grid
                  className={classes.formTransition}
                  style={{ width: helperSituation?.hasHelped ? '50%' : 0 }}
                >
                  <HelpsForm
                    setHelperSituation={setHelpedSituation}
                    helperSituation={helpedSituation}
                    isHelped
                    isRequired={helperSituation?.hasHelped}
                  />
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid
                  className={classes.formTransition}
                  style={{ width: helperSituation?.hasHelped ? '100%' : 0 }}
                >
                  <HelpsForm
                    setHelperSituation={setHelpedSituation}
                    helperSituation={helpedSituation}
                    isHelped
                  />
                </Grid>
              </Hidden>
            </Grid>
            <Grid className={classes.btnContainer}>
              <Button
                type="submit"
                variant="contained"
                disabled={isInvalidForm}
                className={classes.btn}
              >
                {t('CHB.HELP_TOOL.LABEL')}
              </Button>
            </Grid>
          </form>
          {/* RESULTS */}
          <div ref={sectionRefs.current.results}>
            {helperHelps && (
              <Grid container justifyContent="center" style={{ position: 'relative' }}>
                <Grid item md={showHelpedHelps ? 12 : 6} className={classes.contactCardContainer}>
                  <Card className={classes.contactCard}>
                    <CardContent>
                      <Typography variant="h4" component="h3" gutterBottom>
                        {t('CHB.HELP_TOOL.ARTICLE.TITLE')}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {t('CHB.HELP_TOOL.ARTICLE.SUBTITLE')}
                      </Typography>
                    </CardContent>
                    <CardContent style={{ flexShrink: 0 }}>
                      <Button
                        variant="contained"
                        className={classes.articleBtn}
                        component={Link}
                        to="/articles/les-droits-nationaux"
                        target="_blank"
                      >
                        {t('CHB.HELP_TOOL.ARTICLE.LABEL')}
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
                {isLoggedIn && (
                  <Grid item xs={6} align="center">
                    <Button
                      variant="contained"
                      className={classes.articleBtn}
                      style={{ margin: 16 }}
                      onClick={() => handleSendEmail()}
                      startIcon={<EmailIcon />}
                    >
                      {t('CHB.HELP_TOOL.SEND_EMAIL.LABEL')}
                    </Button>
                  </Grid>
                )}
                <Grid container justifyContent="center" className={classes.accordionContainer}>
                  <HelpsAccordion
                    title="Mes aides et mes droits"
                    description="Les aides accordées aux aidants sont indiquées en fonction de la situation du proche accompagné"
                    helps={helperHelps}
                  />
                  {showHelpedHelps && (
                    <HelpsAccordion
                      title="Les aides et les droits de mon proche"
                      description="Les aides accordées aux proches accompagnés sont indiquées en fonction de leur situation"
                      helps={helpedHelps}
                    />
                  )}
                  {!isLoggedIn && <Grid className={classes.gradient} />}
                </Grid>
                {!isLoggedIn && (
                  <Grid item md={6} className={classes.signInCard}>
                    <Card>
                      <CardContent align="center">
                        <Typography variant="h4" component="h3" gutterBottom>
                          {t('CHB.HELP_TOOL.SIGN_IN.TITLE')}
                        </Typography>
                        <Button
                          variant="contained"
                          component={Link}
                          to="/signin?history=goBack"
                          className={classes.btn}
                        >
                          {t('WELLO.SIGN_IN.TITLE')}
                        </Button>
                        <Typography className={classes.userSignUp}>
                          {t('WELLO.NEW_USER')}
                          <Button
                            onClick={() => setOpenSignUp(true)}
                            className={classes.buttonLink}
                            style={{ backgroundColor: 'transparent' }}
                          >
                            {t('WELLO.SIGN_UP')}
                          </Button>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
          {/* CONTACT CARD */}
          <Grid container justifyContent="center">
            {helperHelps && isLoggedIn && (
              <Grid item md={showHelpedHelps ? 12 : 6} className={classes.contactCardContainer}>
                <Card className={classes.contactCard}>
                  <CardContent>
                    <Typography variant="h4" component="h3" gutterBottom>
                      {t('CHB.HELP_TOOL.QUOTATION.TITLE')}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {t('CHB.HELP_TOOL.QUOTATION.SUBTITLE')}
                    </Typography>
                  </CardContent>
                  <CardContent style={{ flexShrink: 0 }}>
                    <ButtonCallMeBackInModal
                      withContainedButton
                      organisation={organisation}
                      withPostCode
                      withEmail
                      withPhone
                      buttonLabel={t('CHB.HELP_TOOL.QUOTATION.LABEL')}
                      descriptionPlaceHolder={t(
                        'CHB.ASK_CONTACT.TEXT_FIELD.DESCRIPTION.PLACEHOLDER',
                      )}
                      gtmOrigin="chb-help-tool"
                      defaultDescription={t('CHB.ASK_CONTACT.TEXT_FIELD.DESCRIPTION.PLACEHOLDER')}
                      requiredDescription
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <Divider style={{ margin: '2rem', background: 'none' }} />
      <Footer />
      <Dialog open={openSignUp} onClose={() => setOpenSignUp(false)}>
        <OrganisationSignUp
          organisation={organisation}
          isDialog
          onSubmitClick={handleSubmitClick}
          title={t('CHB.HELP_TOOL.SIGN_UP.SUBTITLE')}
          subtitle={t('CHB.HELP_TOOL.SIGN_UP.TITLE')}
          signUpRole={Roles.PRIMARY}
          // preventSubmitSignUp
        />
      </Dialog>
      <Dialog open={openSignUpPending} onClose={() => setOpenSignUpPending(false)}>
        <ContactSuccess
          organisation={organisation}
          title={t('WELLO.PENDING_SIGN_UP.TITLE')}
          description={!isLoggedIn && t('WELLO.PENDING_SIGN_UP.DESCRIPTION', { email })}
          subtitle={t('WELLO.PENDING_SIGN_UP.SUB_TITLE')}
        />
      </Dialog>
    </Grid>
  );
};

export default HelpedTool;
