import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  accordion: {
    margin: '0 !important',
    '& .MuiAccordionSummary-root': {
      flexDirection: 'row-reverse',
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 12,
    },
    '&.MuiPaper-elevation1': {
      boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    },
  },
  overline: {
    margin: theme.spacing(1, 0),
  },
}));

type Props = {
  title: string,
  description: String,
  helps: Object,
};

const HelpsAccordion = ({ title, description, helps }: Props) => {
  const classes = useStyles();
  const helpsTitles = Object.keys(helps);

  return (
    <Grid item xs={12} md={6} style={{ margin: 16 }}>
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {description}
          </Typography>
        </CardContent>
        {helpsTitles.map(help => (
          <Accordion
            defaultExpanded={help === helpsTitles[0]}
            key={help}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" component="div">
                {help}
              </Typography>
            </AccordionSummary>
            <Divider light />
            <AccordionDetails style={{ flexDirection: 'column' }}>
              {helps[help].map(h => (
                <Grid key={h?.condition} style={{ marginBottom: 16 }}>
                  <Typography variant="overline" component="div" className={classes.overline}>
                    {h?.title}
                  </Typography>
                  <Typography dangerouslySetInnerHTML={{ __html: h?.description }} />
                  <Typography
                    variant="subtitle2"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: h?.condition }}
                  />
                </Grid>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Card>
    </Grid>
  );
};

export default HelpsAccordion;
