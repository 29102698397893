import React from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import districtsHelps from './HelpsData/districtsHelps';
import pensionFundsHelps from './HelpsData/pensionFundsHelps';
import healthInsuranceHelps from './HelpsData/healthInsuranceHelps';
import foresightHelps from './HelpsData/foresightHelps';
import specialPlanHelps from './HelpsData/specialPlanHelps';
import insuranceHelps from './HelpsData/insuranceHelps';
import { ChbGroupPersonIcon } from '../../Themes/Icon';

const useStyles = makeStyles(theme => ({
  autocomplete: {
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  card: { margin: 16 },
  cardContent: {
    padding: theme.spacing(4),
  },
  titleContainer: {
    marginBottom: theme.spacing(3),
    alignItems: 'center',
  },
  title: {
    marginLeft: theme.spacing(1),
  },
  personIcon: {
    color: theme.chb.primary.main,
    height: '28px !important',
    width: '28px !important',
  },
  groupIcon: {
    height: '28px !important',
    width: '28px !important',
  },
}));

type Props = {
  setHelperSituation: Function,
  helperSituation: Object,
  isHelped: Boolean,
  isRequired: Boolean,
};

const HelpsForm = ({ setHelperSituation, helperSituation, isHelped, isRequired }: Props) => {
  const classes = useStyles();
  const districtsHelpsKeys = Object.keys(districtsHelps);
  const pensionFundsHelpsKeys = Object.keys(pensionFundsHelps);
  const healthInsuranceHelpsKeys = Object.keys(healthInsuranceHelps);
  const foresightHelpsKeys = Object.keys(foresightHelps);
  const specialPlanHelpsKeys = Object.keys(specialPlanHelps);
  const insuranceHelpsKeys = Object.keys(insuranceHelps);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container className={classes.titleContainer}>
          {isHelped ? (
            <ChbGroupPersonIcon className={classes.groupIcon} />
          ) : (
            <PersonIcon className={classes.personIcon} />
          )}
          <Typography variant="h4" className={classes.title}>
            {isHelped ? 'Mon proche' : 'Ma situation'}
          </Typography>
        </Grid>
        {/* pathology */}
        <FormControl component="fieldset" fullWidth required={isRequired} margin="normal">
          <Typography>{isHelped ? 'Sa situation *' : 'Je suis *'}</Typography>
          <Select
            fullWidth
            value={helperSituation.pathology}
            onChange={event =>
              setHelperSituation({ ...helperSituation, pathology: event.target.value })
            }
            input={<OutlinedInput name="pathology" id="pathology" />}
            variant="outlined"
          >
            <MenuItem value="PA">{'Personne âgée'}</MenuItem>
            <MenuItem value="PSH">{'Personne en situation de handicap'}</MenuItem>
            <MenuItem value="EH">{'Enfant en situation de handicap'}</MenuItem>
            {!isHelped && <MenuItem value="other">{'Autre'}</MenuItem>}
          </Select>
        </FormControl>
        {/* hasHelped */}
        {!isHelped && (
          <FormControl component="fieldset" fullWidth required={isRequired} margin="normal">
            <Typography>{"J'accompagne un proche *"}</Typography>
            <Select
              fullWidth
              value={helperSituation.hasHelped}
              onChange={event =>
                setHelperSituation({ ...helperSituation, hasHelped: event.target.value })
              }
              input={<OutlinedInput name="has-helped" id="has-helped-input" />}
              variant="outlined"
            >
              <MenuItem value={true}>{'Oui'}</MenuItem>
              <MenuItem value={false}>{'Non'}</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* district */}
        <FormControl component="fieldset" fullWidth margin="normal">
          <Typography>{isHelped ? 'Son département *' : 'Mon département *'}</Typography>
          <Autocomplete
            fullWidth
            id="district-select"
            options={districtsHelpsKeys}
            autoHighlight
            defaultValue={helperSituation.district}
            renderInput={params => (
              <TextField required={isRequired} {...params} variant="outlined" />
            )}
            onChange={(event, value) => setHelperSituation({ ...helperSituation, district: value })}
            className={classes.autocomplete}
          />
        </FormControl>
        {/* pensionFunds */}
        <FormControl component="fieldset" fullWidth margin="normal">
          <Typography>{isHelped ? 'Sa caisse de retraite' : 'Ma caisse de retraite'}</Typography>
          <Autocomplete
            fullWidth
            id="pensionFunds-select"
            options={pensionFundsHelpsKeys}
            autoHighlight
            defaultValue={helperSituation.pensionFund}
            renderInput={params => <TextField {...params} variant="outlined" />}
            onChange={(event, value) =>
              setHelperSituation({ ...helperSituation, pensionFund: value })
            }
            className={classes.autocomplete}
          />
        </FormControl>
        {/* healthInsurance */}
        <FormControl component="fieldset" fullWidth margin="normal">
          <Typography>
            {isHelped ? 'Sa complémentaire santé' : 'Ma complémentaire santé'}
          </Typography>
          <Autocomplete
            fullWidth
            id="healthInsurance-select"
            options={healthInsuranceHelpsKeys}
            autoHighlight
            defaultValue={helperSituation.healthInsurance}
            renderInput={params => <TextField {...params} variant="outlined" />}
            onChange={(event, value) =>
              setHelperSituation({ ...helperSituation, healthInsurance: value })
            }
            className={classes.autocomplete}
          />
        </FormControl>
        {/* foresight */}
        <FormControl component="fieldset" fullWidth margin="normal">
          <Typography>{isHelped ? 'Sa prévoyance' : 'Ma prévoyance'}</Typography>
          <Autocomplete
            fullWidth
            id="foresight-select"
            options={foresightHelpsKeys}
            autoHighlight
            defaultValue={helperSituation.foresight}
            renderInput={params => <TextField {...params} variant="outlined" />}
            onChange={(event, value) =>
              setHelperSituation({ ...helperSituation, foresight: value })
            }
            className={classes.autocomplete}
          />
        </FormControl>
        {/* specialPlan */}
        <FormControl component="fieldset" fullWidth margin="normal">
          <Typography>{isHelped ? 'Son régime spécial' : 'Mon régime spécial'}</Typography>
          <Autocomplete
            fullWidth
            id="specialPlan-select"
            options={specialPlanHelpsKeys}
            autoHighlight
            defaultValue={helperSituation.specialPlan}
            renderInput={params => <TextField {...params} variant="outlined" />}
            onChange={(event, value) =>
              setHelperSituation({ ...helperSituation, specialPlan: value })
            }
            className={classes.autocomplete}
          />
        </FormControl>
        {/* insurance  */}
        <FormControl component="fieldset" fullWidth margin="normal">
          <Typography>{isHelped ? 'Son assurance' : 'Mon assurance'}</Typography>
          <Autocomplete
            fullWidth
            id="insurance-select"
            options={insuranceHelpsKeys}
            autoHighlight
            defaultValue={helperSituation.insurance}
            renderInput={params => <TextField {...params} variant="outlined" />}
            onChange={(event, value) =>
              setHelperSituation({ ...helperSituation, insurance: value })
            }
            className={classes.autocomplete}
          />
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default HelpsForm;
